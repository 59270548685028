import React, { useState } from 'react';

import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import styles from './RemoveFromBlacklistButton.module.scss';
import CustomerService from '../../services/CustomerService';
import ModalPopUp from '../Modal/ModalPopUp';
import Spinner from '../Spinner/Spinner';

function RemoveFromBlacklistButton({ type, blacklistItem }) {
  const [popUpOpen, setPopUpOpen] = useState(false);
  return (<>
    <FontAwesomeIcon icon={faTrashCan} onClick={() => setPopUpOpen(true)} className={styles.removeIcon} />
    {popUpOpen && <ConfirmationPopUp type={type} item={blacklistItem} onClose={() => setPopUpOpen(false)} />}
  </>);
}

function ConfirmationPopUp({ type, item, onClose }) {
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    setLoading(true);
    const remove = type == 'email' ? 
      await CustomerService.removeEmailFromBlacklist(item.companyId, item.id) : 
      await CustomerService.removePostcodeFromBlacklist(item.companyId, item.id);
    if (remove.success) {
      toast.success(`Successfully unblacklisted ${type == 'email' ? item.email : item.postcode}`);
      onClose();
      item.refresh();
    } else {
      toast.error(`Error when trying to remove ${type == 'email' ? item.email : item.postcode} from your blacklist`);
    }
    setLoading(false);
  };

  return (<ModalPopUp isOpen={true} onClose={onClose} disabled={loading}>
    {loading && <Spinner loading={true} />}
    <h3 className={styles.title}>Unblacklist {type}</h3>
    <p>
      Are you sure you want to remove <span className={styles.highlightedText}>
        {type == 'email' ? item.email : item.postcode}
      </span> from your blacklist?
    </p>
    <div className={styles.buttonsContainer}>
      <button onClick={onClose}>No</button>
      <button onClick={onConfirm}>Yes</button>
    </div>
  </ModalPopUp>);
}

export default RemoveFromBlacklistButton;
