import React, { useState, useCallback, useEffect } from 'react';

import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faFileArrowUp, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useDropzone} from 'react-dropzone';
import { toast } from 'react-toastify';

import styles from './Upload.module.scss';
import ListComponent from '../../components/ListComponent/ListComponent';
import Spinner from '../../components/Spinner/Spinner';
import TabLayout from '../../components/TabLayout/TabLayout';
import { PAPERPLANES_ID } from '../../config';
import { usePermissions } from '../../hooks/usePermissions';
import PdfService from '../../services/PdfService';

function Upload() {
  const {userPermissions} = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;
  
  return (<>
    {isPPAccount? <PPView /> : <ClientView />}
  </>
  );
}

function ClientView() {
  return (
    <TabLayout tabs={[
      {label: 'Creative', render: () => <ClientPdfs />},
      /* {label: 'Transaction data', render: () => <ClientTransactionData />}, */
    ]} />
  );
}

/* function ClientTransactionData() {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();

  const columns = [
    'fileName', 'dateUploaded', 'fileSize',
  ];
  
  const searchKeys = ['fileName'];

  const loadFiles = async () => {
    setLoading(true);
    const getPdfList = await PdfService.getPdfOrCompanyList();

    if (getPdfList.success) {
      setFileList(getPdfList.data.pdfFiles);
    } else {
      toast.error('Failed to load files. Please try again.');
    };
    setLoading(false);
  };

  useEffect(() => {
    loadFiles();
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
    maxFiles: 1,
    onDropRejected: (fileRejections) => {
      if (fileRejections.length > 1) {
        toast.error('Only 1 file is allowed');
      } else {
        toast.error('Invalid file');
      }
    },
  });

  const submit = () => {
    console.log(file);
  };

  const clear = () => {
    setFile(null);
  };
  
  return (<div className={styles.container}>
    {loading && <Spinner loading={true} />}
    <div className={styles.tableContainer}>
      {!loading && <ListComponent
        columns={columns}
        data={fileList}
        searchKeys={searchKeys}
        searchBarFullSize={true}
      />}
    </div>
    <div className={styles.uploadContainer}>
      <div className={styles.upload}>
        <div {...getRootProps({className: `${styles.dragAndDrop}`})}>
          <input {...getInputProps()} />
          <div>
            <h2>
              <FontAwesomeIcon icon={faFileArrowUp} size='2x'/><br />
              Drag and drop<br />
              or <span className={styles.chooseFile}>choose a CSV</span>
            </h2>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <button className={styles.clearButton} onClick={clear}>Clear</button>
          <button className={styles.submitButton} onClick={submit}>Submit</button>
        </div>
        <div className={styles.files}>
          {file && <File file={file} remove={clear} />}
        </div>
      </div>
    </div>
  </div>);
} */

function ClientPdfs() {
  const [pdfList, setPdfList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const columns = [
    'fileName', 'dateUploaded', 'fileSize',
  ];

  const onDrop = useCallback((acceptedFiles) => {
    setFilesToUpload(acceptedFiles);
  }, [filesToUpload]);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
    },
  });

  const removeFile = (file) => {
    const newFiles = [...filesToUpload];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFilesToUpload(newFiles);
  };

  const removeAll = () => {
    setFilesToUpload([]);
  };

  const submitFiles = async () => {
    if (filesToUpload.length < 1) {
      toast.error('No PDFs selected');
      return;
    }
    const uploadedPdf = await PdfService.uploadPdfs(filesToUpload);
    for (const resp of uploadedPdf) {
      if (resp.success) toast.success('PDF uploaded successfully');
      else toast.error('Failed to upload PDF');
    }
    loadPDFs();
    removeAll();
  };

  const loadPDFs = async () => {
    setLoading(true);
    const getPdfList = await PdfService.getPdfOrCompanyList();

    if (getPdfList.success) {
      setPdfList(getPdfList.data.pdfFiles);
    } else {
      toast.error('Failed to load PDFs. Please try again.');
    };
    setLoading(false);
  };

  useEffect(() => {
    loadPDFs();
  }, []);

  const searchKeys = ['fileName'];

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        {!loading && <ListComponent
          columns={columns}
          data={pdfList}
          searchKeys={searchKeys}
          searchBarFullSize={true}
        />}
        <Spinner loading={loading} />
      </div>
      <div className={styles.uploadContainer}>
        <div className={styles.upload}>
          <div {...getRootProps({className: `${styles.dragAndDrop} dropzone`})}>
            <input {...getInputProps()} />
            <h2>
              <FontAwesomeIcon icon={faFileArrowUp} size='2x'/><br />
              Drag & Drop PDFs here,<br />
              or <span className={styles.chooseFile}>choose PDFs</span>
            </h2>
          </div>
          <div className={styles.buttonsContainer}>
            <button className={styles.clearButton} onClick={removeAll}>Clear</button>
            <button className={styles.submitButton} onClick={submitFiles}>Submit</button>
          </div>
          <div className={styles.files}>
            {filesToUpload.map((file) => <File key={file.path} file={file} remove={() => removeFile(file)} />)}
          </div>
        </div>
      </div>
    </div>
  );
}

function PPView() {
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const columns = ['companyUploads'];

  const loadCompanies = async () => {
    setLoading(true);
    const getCompanyList = await PdfService.getPdfOrCompanyList();

    if (getCompanyList.success) {
      setCompanyList(getCompanyList.data.companies);
    } else {
      toast.error('Failed to load companies. Please try again.');
    };
    setLoading(false);
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  const searchKeys = ['name'];

  return (<div className={styles.mainContent}>
    <div className={styles.tableHeader}>
      <h1 className={styles.heading}>Uploads</h1>
    </div>
    <div className={styles.ppTable}>
      <div style={{overflow: 'auto', height: '80vh'}}>
        <p>Select a company</p>
        {!loading && companyList && 
        (<ListComponent
          columns={columns}
          data={companyList}
          searchKeys={searchKeys}
          searchBarFullSize={true}
        />)}
      </div>
    </div>
    <Spinner loading={loading} />
  </div>);
}

function File({ file, remove }) {
  console.log(file);
  return (<div className={styles.file}>
    <FontAwesomeIcon className={styles.fileIcon} icon={faFile} />
    {file.name}
    <span className={styles.right}>
      <span className={styles.fileSize}>{file.size} B</span>
      <button onClick={remove} className={styles.removeFileButton}>
        <FontAwesomeIcon icon={faX} />
      </button>
    </span>
  </div>);
}

export default Upload;
