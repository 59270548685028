import React, { useState, useEffect } from 'react';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

import styles from './ApiDataPreview.module.scss';
import ModalPopUp from '../../components/Modal/ModalPopUp';
import Spinner from '../../components/Spinner/Spinner';
import { usePermissions } from '../../hooks/usePermissions';
import ApiKeyService from '../../services/ApiKeyService';
import CampaignService from '../../services/CampaignService';

function JsonCell({ obj }) {
  const [popUpOpen, setPopUpOpen] = useState(false);
  return (<>
    <div className={styles.jsonCell} onClick={() => setPopUpOpen(true)}>
      {JSON.stringify(obj)}
    </div>
    {popUpOpen && <ModalPopUp isOpen={true}
      onClose={() => setPopUpOpen(false)} 
      includeCloseX={true}
    >
      <pre className={styles.jsonDisplay}>{JSON.stringify(obj, null, 2)}</pre>
    </ModalPopUp>}
  </>);
}

function ApiDataPreview() {
  const [segments, setSegments] = useState([]);
  const [currSegment, setCurrSegment] = useState('');
  const [segmentData, setSegmentData] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { userPermissions } = usePermissions();
  const pageSize = 10;

  const loadSegments = async () => {
    setLoading(true);
    const getSegments = await CampaignService.getSegmentList(userPermissions.companyId);
    setLoading(false);
    if (getSegments.success) {
      setSegments(getSegments.data.segmentIds);
      if (getSegments.data.segmentIds.length > 0) {
        setCurrSegment(getSegments.data.segmentIds[0]);
      }
    }
  };

  useEffect(() => {
    loadSegments();
  }, []);

  useEffect(() => {
    if (!currSegment) return;
    getFirstPage();
  }, [currSegment]);

  const getFirstPage = async () => {
    setSegmentData(null);
    await getSegmentData();
    setPage(1);
  };

  const getSegmentData = async (lastKey=null, back=false) => {
    setLoading(true);
    const response = await ApiKeyService.getSegmentData(
        userPermissions.companyId,
        currSegment,
        lastKey,
        pageSize,
        back,
    );

    if (response.success) {
      setSegmentData(response.data);
    } else {
      toast.error(response.error);
    }
    setLoading(false);
  };

  const next = async () => {
    await getSegmentData(segmentData.nextKey);
    setPage((prev) => prev+1);
  };

  const prev = async () => {
    await getSegmentData(segmentData.prevKey, true);
    setPage((prev) => prev-1);
  };

  const columnDefs = [
    {name: 'Email', selector: (item) => item.email},
    {name: 'First Name', selector: (item) => item.firstName},
    {name: 'Last Name', selector: (item) => item.lastName},
    {name: 'Address 1', selector: (item) => item.address1},
    {name: 'Address 2', selector: (item) => item.address2},
    {name: 'Postcode', selector: (item) => item.postcode},
    {name: 'City', selector: (item) => item.city},
    {name: 'Products', selector: (item) => <JsonCell obj={item.products} />, width: '300px'},
    {name: 'Other', selector: (item) => <JsonCell obj={item.otherAttributes} />, width: '300px'},
  ];

  return (<div className={styles.mainContainer}>
    {loading && <Spinner loading={true} />}
    <h3>Your Segments</h3>
    <div className={styles.segmentSelection}>
      <select value={currSegment} onChange={(e) => setCurrSegment(e.target.value)}>
        {segments.map((s, index) => <option key={index} value={s}>{s}</option>)}
      </select>
    </div>
    {!loading && segmentData && <div className={styles.tableContainer}>
      <div className={styles.tableWrapper}>
        <DataTable
          columns={columnDefs}
          data={segmentData.results}
          pagination={false}
          paginationRowsPerPageOptions={pageSize}
          responsive
          fixedHeader
          fixedHeaderScrollHeight="auto"
          noDataComponent={<div className={styles.noDataContainer}>
            No more records
            <button onClick={getFirstPage}>Return to start</button>
          </div>}
          customStyles={{
            responsiveWrapper: {
              style: {
                overflow: 'visible',
              },
            },
          }}
        />
      </div>
      {segmentData.results.length > 0 && <div className={styles.pagination}>
        <button disabled={page == 1} onClick={getFirstPage}>
          <FontAwesomeIcon icon={faChevronLeft} /><FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button disabled={page == 1} onClick={prev}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className={styles.pageNumber}>{page}</div>
        <button disabled={segmentData.results.length < pageSize} onClick={next}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>}
    </div>}
  </div>);
}

export default ApiDataPreview;
