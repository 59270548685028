import React, { useState, useEffect } from 'react';

import { faClone } from '@fortawesome/free-regular-svg-icons';
import { faRotateRight, faEye, faEyeSlash, faUpRightFromSquare, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './ApiWebhookConnection.module.scss';
import paperPlane from '../../assets/images/paperplane.png';
import ConnectionSection from '../../components/ConnectKeysSection/ConnectionSection';
import FieldWrapper from '../../components/Form/FieldWrapper';
import ModalPopUp from '../../components/Modal/ModalPopUp';
import Spinner from '../../components/Spinner/Spinner';
import ApiKeyService from '../../services/ApiKeyService';

function useApiKey() {
  const [apiKey, setApiKey] = useState();
  const [exists, setExists] = useState();

  const checkApiKey = async () => {
    const response = await ApiKeyService.checkPaperplanesApiKey();
    setExists(response.success);
  };

  const generateApiKey = async () => {
    const response = await ApiKeyService.generatePaperplanesApiKey();
    if (response.success) {
      setApiKey(response.data.apiKey);
      toast.success('Successfully generated API key');
    } else {
      toast.error(response.error.error);
    }
    return response.success;
  };

  const retrieveApiKey = async (password) => {
    const response = await ApiKeyService.retrievePaperplanesApiKey(password);
    if (response.success) {
      setApiKey(response.data.apiKey);
    } else {
      toast.error(response.error.error);
    }
    return response.success;
  };

  return {apiKey, exists, setExists, checkApiKey, generateApiKey, retrieveApiKey};
}

function ActionsButton({ viewApiKey }) {
  return (<Menu as='div' className={styles.menuContainer}>
    <Menu.Button className={styles.menuButton}>
      Actions
      <FontAwesomeIcon icon={faCaretDown} className={styles.dropdownIcon} />
    </Menu.Button>
    <Menu.Items className={styles.menuItems}>
      <Menu.Item>
        {() => (
          <button onClick={viewApiKey} className={styles.menuItem}>
            View API Key
          </button>
        )}
      </Menu.Item>
      <Menu.Item>
        {() => (
          <Link to='/api_data_preview' target='_blank' rel='noopener noreferrer'
            className={styles.menuItem}>
            Preview Data <FontAwesomeIcon icon={faUpRightFromSquare} />
          </Link>
        )}
      </Menu.Item>
    </Menu.Items>
  </Menu>);
}

function ApiWebhookConnection() {
  const { apiKey, exists, setExists, checkApiKey, generateApiKey, retrieveApiKey } = useApiKey();
  const [popUpOpen, setPopUpOpen] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkExists = async () => {
      setLoading(true);
      await checkApiKey();
      setLoading(false);
    };
    checkExists();
  }, []);

  const handleGenerate = async () => {
    setLoading(true);
    const success = await generateApiKey();
    if (success) {
      setExists(true);
      setPopUpOpen('apiKey');
    }
    setLoading(false);
  };

  return (<>
    <ConnectionSection
      image={<img src={paperPlane} />}
      title='API/Webhook'
      description='Set up direct API integration with Paperplanes.'
      infoURL='https://www.paperplanes.co.uk'
      button={loading ? 
        <button><Spinner loading={true} fullscreen={false} color='white' size='0.875rem' /></button> :
        exists ?
          <ActionsButton viewApiKey={() => setPopUpOpen('password')} /> :
          <button onClick={handleGenerate}>Generate</button>
      }
    />
    {popUpOpen == 'password' && <PasswordPopUp close={() => setPopUpOpen('')} 
      retrieveApiKey={retrieveApiKey} showApiKey={() => setPopUpOpen('apiKey')} />}
    {popUpOpen == 'apiKey' && <ViewApiKeyPopUp close={() => setPopUpOpen('')} apiKey={apiKey} 
      onRegenerate={() => setPopUpOpen('regenerate')} />}
    {popUpOpen == 'regenerate' && <RegenerateConfirmation close={() => setPopUpOpen('apiKey')} regenerateApiKey={generateApiKey} />}
  </>);
}

function ApiKeyWidget({ apiKey }) {
  const [copied, setCopied] = useState(false);
  const [hidden, setHidden] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('could not copy', err);
    }
  };

  return (<div className={styles.apiKeyWidget}>
    <div className={`${styles.apiKeyContainer} ${hidden ? styles.hidden : ''}`}>
      {hidden ? '*'.repeat(apiKey.length) : apiKey}
    </div>
    <button className={styles.showHideButton} onClick={() => setHidden(!hidden)}><FontAwesomeIcon icon={hidden ? faEyeSlash : faEye} /></button>
    <div className={styles.copyButtonContainer}>
      <button className={styles.copyButton} onClick={handleCopy}><FontAwesomeIcon icon={faClone} /></button>
      {copied && <span className={styles.toolTipText}>Copied!</span>}
    </div>
  </div>);
}

function PasswordPopUp({ close, retrieveApiKey, showApiKey }) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 1) {
      setError('Invalid password');
      return;
    }
    setLoading(true);
    const success = await retrieveApiKey(password);
    setLoading(false);
    if (success) showApiKey();
  };

  return (<ModalPopUp isOpen={true} onClose={close} disabled={loading} title='View API key'>
    {loading && <Spinner loading={true} />}
    <div className={styles.content}>
      <p>For security reasons, please enter your password to access your API key</p>
      <form id='passwordForm' onSubmit={handleSubmit}>
        <FieldWrapper errorMessage={error}>
          <input type='password' 
            name='password' value={password} 
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Enter your password' />
        </FieldWrapper>
      </form>
      <div className={styles.buttonsContainer}>
        <button type='submit' form='passwordForm'>Submit</button>
      </div>
    </div>
  </ModalPopUp>);
}

function ViewApiKeyPopUp({ apiKey, onRegenerate, close }) {
  return (<ModalPopUp isOpen={true} onClose={close} includeCloseX={true}
    title='Your API key'>
    <p>For more information on how to use your API key, check out our <a href='documentationlink'>documentation</a></p>
    <ApiKeyWidget apiKey={apiKey} />
    <button className={styles.regenerateButton} onClick={onRegenerate}>
      <FontAwesomeIcon icon={faRotateRight} />Regenerate
    </button>
  </ModalPopUp>);
}

function RegenerateConfirmation({ close, regenerateApiKey }) {
  const [loading, setLoading] = useState(false);
  const handleConfirm = async () => {
    setLoading(true);
    await regenerateApiKey();
    setLoading(false);
    close();
  };
  return (<ModalPopUp isOpen={true} onClose={close} disabled={loading}
    title='Confirm regeneration'>
    {loading && <Spinner loading={true} />}
    <div className={styles.content}>
      <p>Are you sure you want to regenerate your API key? Your old key will no longer work.</p>
      <div className={styles.buttonsContainer}>
        <button onClick={close}>No</button>
        <button onClick={handleConfirm}>Yes</button>
      </div>
    </div>
  </ModalPopUp>);
}

export default ApiWebhookConnection;
