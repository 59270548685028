import React, { useState, useEffect } from 'react';

import { faEnvelope, faLocationDot, faCirclePlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { z } from 'zod';

import styles from './Customer.module.scss';
import Form from '../../components/Form/Form';
import Input from '../../components/Form/Input';
import ListComponent from '../../components/ListComponent/ListComponent';
import ModalPopUp from '../../components/Modal/ModalPopUp';
import Spinner from '../../components/Spinner/Spinner';
import { useForm, FormProvider } from '../../hooks/useForm';
import CustomerService from '../../services/CustomerService';

function Blacklist({ companyId }) {
  const [method, setMethod] = useState('email');
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  const refresh = async () => {
    setLoading(true);
    method == 'email' ? await loadEmailList() : await loadPostcodeList();
    setLoading(false);
  };

  const loadEmailList = async () => {
    const getEmails = await CustomerService.getBlacklistedEmails(companyId);
    if (getEmails.success) {
      setDataList(getEmails.data.map((item) => {
        return {...item, companyId: companyId, refresh: refresh};
      }));
    } else {
      toast.error('Could not load blacklist');
    }
  };

  const loadPostcodeList = async () => {
    const getPostcodes = await CustomerService.getBlacklistedPostcodes(companyId);
    if (getPostcodes.success) {
      setDataList(getPostcodes.data.map((item) => {
        return {...item, companyId: companyId, refresh: refresh};
      }));
    } else {
      toast.error('Could not load blacklist');
    }
  };

  useEffect(() => {
    refresh();
  }, [method, companyId]);

  return (<div>
    {loading && <Spinner loading={loading} />}
    {/* <div className={styles.heading}><h1>Manage Customers</h1></div> */}
    <div className={styles.content}>
      <p>Blacklisted customers will no longer receive direct mail</p>
      <div className={styles.row}>
        <div>
          <div className={styles.blacklistFilter}>
            <label>
              <FontAwesomeIcon icon={faFilter} className={styles.filterIcon} />
              By
              <select onChange={(e) => setMethod(e.target.value)}>
                <option value='email'>email</option>
                <option value='postcode'>postcode</option>
              </select>
            </label>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <button className={styles.primaryButton} onClick={() => setPopUpOpen(true)}>
            <div className={styles.row}><FontAwesomeIcon icon={faCirclePlus} />Add</div>
          </button>
        </div>
      </div>
      {!loading && <div>
        <ListComponent
          columns={method == 'email' ? 
            ['emailNormal', 'addedAt', 'removeEmailFromBlacklist'] : 
            ['postcode', 'addedAt', 'removePostcodeFromBlacklist']
          }
          data={dataList}
          searchKeys={[method]} />
      </div>}
      {popUpOpen && <AddToBlacklistPopUp defaultType={method} companyId={companyId} close={() => {
        setPopUpOpen(false);
        refresh();
      }} />}
    </div>
  </div>);
}

function AddToBlacklistPopUp({ defaultType='email', companyId, close }) {
  const methods = useForm();
  const [loading, setLoading] = useState(false);

  const submit = () => {
    const schema = z.discriminatedUnion('type', [
      z.object({type: z.literal('email'), email: z.string().min(1, 'Empty!').email('Invalid Email')}),
      z.object({type: z.literal('postcode'), postcode: z.string().min(1, 'Empty!')}),
    ]);

    const valid = methods.validateZod(schema);
    if (!valid) return;
    if (methods.formData['type'] == 'email') {
      blacklistEmail(methods.formData['email']);
    } else {
      blacklistPostcode(methods.formData['postcode']);
    }
  };

  const blacklistEmail = async (email) => {
    setLoading(true);
    const res = await CustomerService.blacklistEmail(companyId, email);
    if (res.success) {
      methods.reset(['email', 'postcode']);
      toast.success(`Successfully blacklisted ${email}`);
    } else {
      toast.error(res.error.error);
    }
    setLoading(false);
  };

  const blacklistPostcode = async (postcode) => {
    setLoading(true);
    const res = await CustomerService.blacklistPostcode(companyId, postcode);
    if (res.success) {
      methods.reset(['email', 'postcode']);
      toast.success(`Successfully blacklisted ${postcode}`);
    } else {
      toast.error(res.error.error);
    }
    setLoading(false);
  };
  
  const choices = [
    {label: <><FontAwesomeIcon icon={faEnvelope} />Email</>, value: 'email'},
    {label: <><FontAwesomeIcon icon={faLocationDot} />Postcode</>, value: 'postcode'},
  ];
  
  return (<>
    <ModalPopUp onClose={close} isOpen={true} disabled={loading} title='Blacklist a customer' includeCloseX={true}>
      {loading && <Spinner loading={loading} />}
      <FormProvider value={methods}>
        <Form onSubmit={submit} className={styles.form}>
          If no email address is available, you can try blacklisting with their postcode
          <Input type='radio' name='type' defaultValue={defaultType} choices={choices} className={styles.radioButton} />
          <div className={styles.row}>
            <div className={styles.textBoxContainer}>
              {methods.formData['type'] === 'email' && <Input type='text' name='email' defaultValue='' placeholder='Enter An Email' />}
              {methods.formData['type'] === 'postcode' && <Input type='text' name='postcode' defaultValue='' placeholder='Enter A Postcode' />} 
            </div>
            <button type='submit' className={styles.primaryButton}>Submit</button>
          </div>
        </Form>
      </FormProvider>
    </ModalPopUp>
  </>);
}

export default Blacklist;
