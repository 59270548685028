import React, { useState } from 'react';

import { faXmarkCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircleInfo, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

import styles from './ColumnDefinitions.module.scss';
import StatusIndicator from './StatusIndicator';
// import filterButton from '../../assets/images/filter-button.png';
import CampaignMenuButton from '../Buttons/CampaignMenuButton';
import CompanyMenuButton from '../Buttons/CompanyMenuButton';
import RemoveFromBlacklistButton from '../Buttons/RemoveFromBlacklistButton';
import UserMenuButton from '../Buttons/UserMenuButton';
import Tooltip from '../Tooltip/Tooltip';

const BLUEBUTTONCOLOUR = '#5b9bd4';

const capitaliseString = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const sortFunction = (propertyName) => (rowA, rowB) => {
  const a = rowA[propertyName];
  const b = rowB[propertyName];

  if (a > b) return 1;
  if (b > a) return -1;
  return 0;
};

const toUkDate = (dateString) => {
  return moment(dateString).format('DD/MM/YYYY');
};

const toUkDateTime = (dateString) => {
  return moment(dateString).format('DD/MM/YYYY HH:mm');
};

function formatBytes(bytes) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
}

const CompanyCreationDateHeader = () => {
  const [infoHovered, setInfoHovered] = useState(false);

  return (
    <>
      <p>Created</p>
      <FontAwesomeIcon icon={faCircleInfo}
        onMouseEnter={() => setInfoHovered(true)}
        onMouseLeave={() => setInfoHovered(false)}
        className={styles.infoIcon}
      />
      {infoHovered && <div style={{ maxWidth: '120px' }}>When the company was loaded in.</div>}
    </>
  );
};

export const allColumnDefinitions = {
  'email': {
    name: 'Email',
    selector: (item) => item.email,
    sortable: true,
    style: {
      color: BLUEBUTTONCOLOUR,
    },
  },
  'firstName': {
    name: 'First name',
    selector: (item) => item.firstName,
    sortable: true,
  },
  'lastName': {
    name: 'Last name',
    selector: (item) => item.lastName,
    sortable: true,
  },
  'companyName': {
    name: 'Company',
    selector: (item) => item.companyName,
    sortable: true,
  },
  'role': {
    name: 'Role',
    selector: (item) => item.role,
    sortable: true,
  },
  'status': {
    name: 'Status',
    selector: (item) => (<StatusIndicator status={item.status} />),
    sortable: true,
    sortFunction: sortFunction('status'),
  },
  'name': {
    name: 'Company name',
    selector: (item) => item.name,
    sortable: true,
    style: {
      color: BLUEBUTTONCOLOUR,
    },
  },
  'mIdsite': {
    name: 'Account ID',
    selector: (item) => item.mIdsite,
    sortable: true,
  },
  'userCount': {
    name: 'Users #',
    selector: (item) => item.userCount,
    sortable: true,
  },
  'createdAt': {
    name: <CompanyCreationDateHeader />,
    selector: (item) => item.createdAt,
    format: (item) => new Date(item.createdAt).toLocaleString('en-GB'),
    sortable: true,
  },
  'campaignName': {
    name: 'Name',
    selector: (item) => item.campaignName,
    sortable: true,
    style: {
      color: BLUEBUTTONCOLOUR,
    },
    grow: 2,
    cell: (item) => (
      <div className={styles.expandableCell}>
        {item.campaignName}
      </div>
    ),
  },
  'client': {
    name: 'Client',
    selector: (item) => item.client,
    sortable: true,
  },
  'type': {
    name: 'Type',
    selector: (item) => item.type,
    sortable: true,
  },
  'source': {
    name: 'Data Source',
    selector: (item) => item.source,
    sortable: true,
  },
  'activationTime': {
    name: 'Activation Time',
    selector: (item) => item.activationTime.split(':').slice(0, 2).join(':'),
    sortable: true,
  },
  'company': {
    name: 'Company',
    selector: (item) => item.company,
    sortable: true,
  },
  'campaignType': {
    name: 'Campaign type',
    selector: (item) => capitaliseString(item.campaignType),
    sortable: true,
  },
  'dataSource': {
    name: 'Data source',
    selector: (item) => capitaliseString(item.dataSource),
    sortable: true,
  },
  'abSplit': {
    name: 'A/B Split?',
    selector: (item) => item.aBSplit !== 100,
    cell: (item) => (<input className={styles.checkboxTick} type='checkbox' disabled
      checked={item.aBSplit !== 100 ? true : false} />
    ),
    sortable: true,
    // sortFunction: sortFunction('abSplit'),
  },
  'lastRun': {
    name: 'Last run',
    cell: (item) => {
      if (!item.lastRunStatus) {
        return '';
      }
      let icon;
      switch (item.lastRunStatus) {
        case 'ERROR':
          icon = <FontAwesomeIcon icon={faXmarkCircle} color='red' />;
          break;
        case 'IN_PROGRESS':
          icon = <FontAwesomeIcon icon={faSpinner} color={BLUEBUTTONCOLOUR} />;
          break;
        default:
          icon = <FontAwesomeIcon icon={faCheckCircle} color='green' />;
      }
      return (
        <Tooltip position='right' toolTipContent={<div>
          <div className={styles.lastRunStatus}>{item.lastRunStatus}</div>
          <div>{toUkDateTime(item.lastRunDate)}</div>
        </div>}>
          {icon}
        </Tooltip>
      );
    },
  },
  'lettersCreated': {
    name: 'Letters created',
    selector: (item) => item.lettersCreated,
  },
  'userActions': {
    // name: <img src={filterButton} alt="Filter" style={{cursor: 'pointer'}}/>,
    button: true,
    cell: (item) => (<UserMenuButton data={item} userId={item.id} refresh={item.refresh} />),
  },
  'companyActions': {
    // name: <img src={filterButton} alt="Filter" style={{cursor: 'pointer'}}/>,
    button: true,
    cell: (item) => (<CompanyMenuButton data={item}
      companyId={item['mIdsite']} refresh={item.refresh} />),
  },
  'campaignActions': {
    // name: <img src={filterButton} alt="Filter" style={{cursor: 'pointer'}}/>,
    button: true,
    cell: (item) => (<CampaignMenuButton campaignData={item}
      campaignId={item.id} refresh={item.refresh} />),
  },
  'fileName': {
    name: 'File Name',
    selector: (item) => item.fileName,
    cell: (item) => (
      <a
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: BLUEBUTTONCOLOUR,
          textDecoration: 'none',
        }}
      >
        {item.fileName}
      </a>
    ),
    sortable: true,
  },
  'dateUploaded': {
    name: 'Date Uploaded',
    selector: (item) => toUkDate(item.dateUploaded),
    sortable: true,
  },
  'fileSize': {
    name: 'File Size',
    selector: (item) => formatBytes(item.fileSize),
    sortable: true,
  },
  'companyUploads': {
    name: 'Company name', 
    selector: (item) => item.name,
    cell: (item) => (
      <Link 
        to={`/upload/${item.mIdsite}`}
        style={{
          color: BLUEBUTTONCOLOUR,
          textDecoration: 'none',
        }}
      >
        {item.name}
      </Link>
    ),
    sortable: true,
  },
  'postcode': {
    name: 'Postcode',
    selector: (item) => item.postcode,
    sortable: true,
  },
  'emailNormal': {
    name: 'Email',
    selector: (item) => item.email,
    sortable: true,
  },
  'addedAt': {
    name: 'Added At',
    selector: (item) => toUkDateTime(item.addedAt),
    sortable: true,
  },
  'removeEmailFromBlacklist': {
    button: true,
    cell: (item) => <RemoveFromBlacklistButton type='email' blacklistItem={item} />,
  },
  'removePostcodeFromBlacklist': {
    button: true,
    cell: (item) => <RemoveFromBlacklistButton type='postcode' blacklistItem={item} />,
  },
};
